import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { StaticImage } from 'gatsby-plugin-image'
type Props = {}

const Experts = (props: Props) => {
  const [[manualTiltAngleX, manualTiltAngleY], setManualTiltAngle] = useState([
    0, 0
  ])

  useEffect(() => {
    if (!window) return
    const { innerWidth, innerHeight } = window

    const handleWindowMouseMove = (event: any) => {
      const { clientX, clientY } = event
      const coordinatesX = ((clientX / innerWidth) * 100) / 3 - 10
      const coordinatesY = ((clientY / innerHeight) * 100) / 3 - 10

      setManualTiltAngle([coordinatesX, coordinatesY])
    }

    window.addEventListener('mousemove', handleWindowMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleWindowMouseMove)
    }
  }, [])

  const expertImages = useStaticQuery(graphql`
    query {
      morgan: imageSharp(fluid: { originalName: { eq: "morgan.s.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
      tertois: imageSharp(fluid: { originalName: { eq: "tertois.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
      apple: imageSharp(fluid: { originalName: { eq: "apple.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
      sony: imageSharp(fluid: { originalName: { eq: "sony.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
      samsung: imageSharp(fluid: { originalName: { eq: "samsung.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
      google: imageSharp(fluid: { originalName: { eq: "google.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
      justeat: imageSharp(fluid: { originalName: { eq: "justeat.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
      purple: imageSharp(fluid: { originalName: { eq: "purple.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
    }
  `)

  return (
    <section
      id="experts"
      className="container marketing-background-black xl:pt-10">
      <div className="flex flex-col items-center  justify-center mt-[120px] xl:flex-row ">
        <div className="px-4 flex flex-col items-center justify-center sm:items-start md:items-center">
          <div className="w-[350px] h-[494px] sm:w-[455px] sm:h-[641px]">
            {' '}
            <Morgan
              manualTiltAngleX={manualTiltAngleX}
              manualTiltAngleY={manualTiltAngleY}
            />
          </div>

          {/* <GatsbyImage
            image={expertImages.morgan.gatsbyImageData}
            alt="Morgan"
            class="w-[350px] h-[494px] sm:w-[455px] sm:h-[641px]"
          /> */}
          <h1 className="text-white  font-semibold text-5xl tracking-[-0.01em] leading-[51px]">
            Morgan Selbekk
          </h1>
          <div className="flex justify-between gap-10 sm:gap-24 lg:w-1/2 lg:gap-12 xl:w-4/5 xl:gap-8 py-10 px-2">
            <GatsbyImage
              image={expertImages.apple.gatsbyImageData}
              alt="Apple"
              class="brightness-50"
            />
            <GatsbyImage
              image={expertImages.sony.gatsbyImageData}
              alt="Sony"
              class=" brightness-50 "
            />
            <GatsbyImage
              image={expertImages.samsung.gatsbyImageData}
              alt="Samsung"
              class="brightness-50"
            />
          </div>
        </div>
        <div className="px-4 xl:px-12 xl:-mt-40 ">
          <h1 className="text-[#ddff8d] font-medium text-5xl xl:text-[100px] xl:leading-[84px]  leading-[38px] tracking-[-0.03em]">
            Who we are
          </h1>
          <p className=" text-white marketing-paragraph-style marketing-background-black py-8">
            We understand that the future of the internet is already here, and
            we&apos;re committed to ensuring that our clients don&apos;t get
            left behind. With our innovative solutions and cutting-edge
            strategies, we enable businesses to thrive in the ever-evolving
            digital landscape.
          </p>
          <p className="text-white marketing-paragraph-style marketing-background-black">
            Get ready to take your brand to the next level with our exceptional
            services.
          </p>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center mt-[75px] xl:flex-row-reverse xl:gap-44 xl:pb-24">
        <div className="px-4 xl:px-0 flex flex-col items-center justify-center sm:items-start md:items-center">
          <div className="w-[350px] h-[494px] sm:w-[455px] sm:h-[641px]">
            {' '}
            <Georges
              manualTiltAngleX={manualTiltAngleX}
              manualTiltAngleY={manualTiltAngleY}
            />
          </div>

          {/* <GatsbyImage
            image={expertImages.tertois.gatsbyImageData}
            alt="Georges Tertois"
            class="w-[350px] h-[494px] sm:w-[455px] sm:h-[641px]"
          /> */}
          <h1 className="text-white font-semibold text-5xl tracking-[-0.01em] leading-[51px] sm:pt-12">
            Georges Tertois{' '}
          </h1>
          <div className="flex justify-between gap-8 sm:gap-20 lg:w-3/5 lg:gap-8 xl:w-4/5 xl:gap-6   py-10 px-3">
            <GatsbyImage
              image={expertImages.google.gatsbyImageData}
              alt="Google"
              class=" brightness-50 "
            />
            <GatsbyImage
              image={expertImages.justeat.gatsbyImageData}
              alt="Just eat"
              class="brightness-50    "
            />
            <GatsbyImage
              image={expertImages.purple.gatsbyImageData}
              alt="Purple Bricks"
              class="brightness-50    "
            />
          </div>
        </div>{' '}
        <div className="px-4 xl:px-0 xl:pl-6 pb-24">
          <p className="text-white marketing-paragraph-style marketing-background-black py-4">
            Visionary leaders in the Web3 digital space. We pride ourselves on
            being the leading global creative and media agency that&apos;s
            redefining the advertising, design, and development landscape for
            the modern era of the internet.
          </p>
          <p className="text-white marketing-paragraph-style marketing-background-black">
            {' '}
            Our team of experts specialise in a range of disciplines and partner
            with clients to deliver an unparalleled end-to-end digital
            experience, regardless of where they are in their journey.
          </p>
        </div>
      </div>
    </section>
  )
}

interface IMGPROPS {
  manualTiltAngleX: number
  manualTiltAngleY: number
}

import Tilt from 'react-parallax-tilt'

const Morgan: React.FC<IMGPROPS> = ({ manualTiltAngleX, manualTiltAngleY }) => (
  <Tilt
    tiltAngleXManual={manualTiltAngleY}
    tiltAngleYManual={manualTiltAngleX}
    className="mx-auto max-w-[250px] h-[400px] lg:max-w-[400px] lg:h-[600px] layerd-tilt w-full">
    <StaticImage
      className="!absolute w-full left-2 top-2 layer-1"
      src="../images/founders/people/paul_1.png"
      alt="computer_img"
      placeholder="blurred"
      layout="fullWidth"
      quality={100}
    />

    <StaticImage
      className="!absolute w-full left-2 top-2 layer-2"
      src="../images/founders/people/morgan_1.png"
      alt="computer_img"
      placeholder="blurred"
      layout="fullWidth"
      quality={100}
    />

    <StaticImage
      className="!absolute w-full top-0 layer-3 "
      src="../images/founders/people/morgan.png"
      alt="computer_img"
      placeholder="blurred"
      layout="fullWidth"
      quality={100}
    />
  </Tilt>
)
const Georges: React.FC<IMGPROPS> = ({
  manualTiltAngleX,
  manualTiltAngleY
}) => (
  <Tilt
    tiltAngleXManual={manualTiltAngleY}
    tiltAngleYManual={manualTiltAngleX}
    className="mx-auto max-w-[250px] h-[400px] lg:max-w-[400px] lg:h-[600px] layerd-tilt w-full">
    <StaticImage
      className="!absolute w-full left-2 top-2 layer-1"
      src="../images/founders/people/back.png"
      alt="computer_img"
      placeholder="blurred"
      layout="fullWidth"
      quality={100}
    />

    <StaticImage
      className="!absolute w-full left-2 top-2 layer-2"
      src="../images/founders/people/george_2.png"
      alt="computer_img"
      placeholder="blurred"
      layout="fullWidth"
      quality={100}
    />

    <StaticImage
      className="!absolute w-full top-0 layer-3 "
      src="../images/founders/people/george.png"
      alt="computer_img"
      placeholder="blurred"
      layout="fullWidth"
      quality={100}
    />
  </Tilt>
)
export default Experts
