import React from 'react'

type Props = {}

const Web2to3 = (props: Props) => {
  return (
    <section id="web2 to web3" className="container  max-w-[1920px]  flex flex-col items-center justify-center xl:flex-row secondary-font">
      <div className="web2to3-container bg-[#F1F3E5]">
        <h1 className="web2to3-heading text-[#141414]">
          WEB 2.0
        </h1>
        <p className="web2to3-paragraph text-[#333549]">
          PROGRAMMATIC ADVERTISING, BLOCKCHAIN ENABLED STRATEGY
        </p>
      </div>
      <div className="web2to3-container marketing-background-black">
        <h1 className="web2to3-heading text-[#f1f3e5]">
          WEB 2.5
        </h1>
        <p className="web2to3-paragraph text-[#f1f3e5]">
          END TO END CREATIVE, CAMPAIGNS, STRATEGY AND SERVICES{' '}
        </p>
      </div>
      <div className="web2to3-container bg-[#DDFF8D]">
        <h1 className="web2to3-heading text-[#141414]">
          WEB 3.0
        </h1>
        <p className="web2to3-paragraph text-[#141414]">
          ELEVATE YOUR BRAND AND STEP INTO THE WORLD OF WEB 3.0
        </p>
      </div>
    </section>
  )
}

export default Web2to3
