import * as React from 'react'
import Content_Container from '../layout/Content_Container'
// import Header from '../components/layout/Header'
// import Footer from '../components/layout/Footer'
import { useState } from 'react'
import Intro from '../components/marketing/Intro'
import Header from '../components/marketing/Header'
import Services from '../components/marketing/Services'
import Web2to3 from '../components/marketing/Web2to3'
import Testimonials from '../components/marketing/Testimonials'
import Newsletter from '../components/marketing/Newsletter'
import Experts from '../components/marketing/Experts'
import Footer from '../components/marketing/Footer'
import PopUp from '../components/marketing/PopUp'
import { HeadFC } from 'gatsby'

const marketing = () => {
  const [visible, setVisible] = useState(false)
  const [navColor, setNavColor] = useState('black')
  const black = false
  return (
    <Content_Container background={'bg-[#141414]'}>
      <Header visible={visible} navColor={navColor} />
      {/* <Header/> */}
      <Intro />

      <Services />

      <Web2to3 />
      <Experts />

      <Testimonials heading={''} quote={''} imageName={''} imageIcon={''} />

      <Newsletter />

      <Footer background={black ? 'bg-black' : 'bg-blue'} />

      <PopUp />
    </Content_Container>
  )
}

export const Head: HeadFC = () => (
  <>
    <title>Eidgensi | Scale with Innovation</title>
    <meta
      name="description"
      content="We are a creative media agency that enables advertising, design & development for the new era of the internet."
    />
    <meta
      name="keywords"
      content="Eidgensi, Eidgensi agency, Eidgensi creative agency, WEB 3, WEB 2, WEB 2.5, Marketing, supply & demand, Advertising, Web dev, media, branding, design, development, creative, creative agency, creative media agency, creative media, creative media"
    />
    <meta name="og:title" content="Eidgensi" />
    <meta
      name="og:description"
      content="We are a creative media agency that enables advertising, design & development for the new era of the internet."
    />
    <meta name="og:image" content="https://eidgensi.com/image/OG.jpg" />
    <meta name="og:url" content="https://eidgensi.com" />
    <meta name="og:type" content="website" />
    <meta name="og:site_name" content="Eidgensi" />
    <meta name="og:width" content="1200" />
    <meta name="og:height" content="630" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@eidgensi" />
    <meta name="twitter:creator" content="@eidgensi" />
    <meta name="twitter:title" content="Eidgensi" />
    <meta
      name="twitter:description"
      content="We are a creative media agency that enables advertising, design & development for the new era of the internet."
    />
    <meta name="twitter:image" content="https://eidgensi.com/image/OG.jpg" />
    <meta name="twitter:image:alt" content="Eidgensi" />
    <meta name="twitter:domain" content="https://eidgensi.com" />
    <meta name="twitter:width" content="1200" />
    <meta name="twitter:height" content="630" />

    <meta name="viewport" content="width=device-width, initial-scale=1" />
  </>
)

export default marketing
