import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

type Props = {
  heading: string
  quote: string
  imageName: string
  imageIcon: string
}

const TestimonialsData = [
  {
    id: 201,
    heading: 'Quote from Linden Leadbetter, Head of Token Economics:',
    quote: `Eidgensi surpassed our expectations with their exceptional creativity and rapid execution in designing and building out our NFT series. Their designs were so good we used them to redesign our main website. They offered a cost-effective solution and were responsive and collaborative throughout - including their post-completion support which instilled a real sense of confidence in our project's success. Highly recommended!`,

    imageName: 'jenny',
    imageIcon: 'jennyicon'
  },
  {
    id: 202,
    heading: 'Quote from Ben Putley, CEO of Alkimi:',
    quote:
      "From traditional branding and media services all the way to a team of Web3 specialists who were not only able to deliver what we asked for but what we needed to successfully launch a end-to-end Web3 campaign. These kind of services aren't available in more traditional media agencies, let alone offering clients the unique expertise in such a new and fast moving space.",
    imageName: 'ben',
    imageIcon: 'alkimi'
  }
]

const Testimonials = ({ heading, quote, imageName, imageIcon }: Props) => {
  const marketingImages = useStaticQuery(graphql`
    query {
      jenny: imageSharp(fluid: { originalName: { eq: "jenny.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
      jennyicon: imageSharp(fluid: { originalName: { eq: "jennyicon.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
      ben: imageSharp(fluid: { originalName: { eq: "testimonials_ben.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
      alkimi: imageSharp(fluid: { originalName: { eq: "alkimilogo.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
    }
  `)

  return (
    <section id="testimonials" className=" bg-[#F1F3E5]">
      <div className=" container flex flex-col justify-center items-center text-[#141414] text-center gap-12 pb-24">
        <h1 className="font-medium text-[64px] tracking-[-0.03em] leading-[46px] text-[#141414] text-center px-5 pt-28">
          What our clients say about us
        </h1>

        {TestimonialsData.map((client) => {
          const clientImage = marketingImages[client.imageName]
          const iconImage = marketingImages[client.imageIcon]

          return (
            <div className="container flex flex-col justify-center items-center gap-6 ">
              {/* client image */}
              <GatsbyImage
                image={clientImage.gatsbyImageData}
                alt="Jenny"
                class="rounded-full w-[207px] h-[206px]"
              />

              {/* iconimage */}
              <GatsbyImage
                image={iconImage.gatsbyImageData}
                alt="jenny.co"
                class="w-[198px] h-[40px] my-6"
              />

              <h2 className="uppercase font-normal text-xl lg:text-[34px] secondary-font max-w-[1060px] leading-none">
                {client.heading}
              </h2>

              <div className="max-w-[1060px]">
                <svg
                  className="mr-2 mb-8 inline"
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.4751 18C12.2616 18 11.4077 17.6075 10.9133 16.8224C10.4189 15.9813 10.1717 14.972 10.1717 13.7944V13.7103C10.1717 13.3738 10.1942 12.7009 10.2391 11.6916C10.329 10.6262 10.5088 9.4486 10.7784 8.15888C11.093 6.81308 11.5425 5.52336 12.1268 4.28972C12.711 3.05607 13.52 2.04673 14.5537 1.26168C15.5874 0.42056 16.9133 0 18.5312 0V5.97196C16.8234 5.97196 15.5425 6.36449 14.6886 7.14953C13.8796 7.8785 13.3178 8.69159 13.0032 9.58878H13.4077C14.6661 9.58878 15.5425 10.0093 16.0369 10.8505C16.5312 11.6355 16.7784 12.6168 16.7784 13.7944C16.7784 14.972 16.5312 15.9813 16.0369 16.8224C15.5425 17.6075 14.6886 18 13.4751 18ZM3.83462 18C2.57619 18 1.69979 17.6075 1.20541 16.8224C0.755969 15.9813 0.53125 14.972 0.53125 13.7944V13.7103C0.53125 13.3738 0.553722 12.7009 0.598666 11.6916C0.688553 10.6262 0.868329 9.4486 1.13799 8.15888C1.40765 6.81308 1.83462 5.52336 2.41889 4.28972C3.0481 3.05607 3.87957 2.04673 4.91327 1.26168C5.94698 0.42056 7.25035 0 8.82339 0V5.97196C7.16046 5.97196 5.90204 6.36449 5.0481 7.14953C4.23912 7.8785 3.65485 8.69159 3.2953 9.58878H3.69979C5.00316 9.58878 5.90204 10.0093 6.39642 10.8505C6.8908 11.6355 7.13799 12.6168 7.13799 13.7944C7.13799 14.972 6.8908 15.9813 6.39642 16.8224C5.90204 17.6075 5.0481 18 3.83462 18Z"
                    fill="#141414"
                  />
                </svg>
                <p className="text-[26px] lg:text-[34px] font-semibold inline">
                  {client.quote}
                </p>
                <svg
                  className="ml-2 mb-8 inline"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0 18V12.028C1.66292 12.028 2.89888 11.6636 3.70786 10.9346C4.5618 10.2056 5.14607 9.36449 5.46067 8.41121H5.1236C3.82022 8.41121 2.92135 8.01869 2.42697 7.23364C1.93258 6.39252 1.68539 5.38318 1.68539 4.20561C1.68539 3.02804 1.93258 2.04673 2.42697 1.26168C2.92135 0.42056 3.77528 0 4.98876 0C6.20225 0 7.05618 0.42056 7.55056 1.26168C8.04494 2.04673 8.29214 3.02804 8.29214 4.20561C8.29214 4.26168 8.29214 4.28972 8.29214 4.28972C8.29214 4.68224 8.24719 5.41122 8.1573 6.47663C8.11236 7.48598 7.93258 8.63551 7.61798 9.92523C7.34832 11.215 6.92135 12.5047 6.33708 13.7944C5.75281 15.028 4.94382 16.0374 3.91011 16.8224C2.8764 17.6075 1.57303 18 0 18ZM9.64045 18V12.028C11.3034 12.028 12.5393 11.6636 13.3483 10.9346C14.2022 10.2056 14.809 9.36449 15.1685 8.41121H14.764C13.5056 8.41121 12.6292 8.01869 12.1348 7.23364C11.6405 6.39252 11.3933 5.38318 11.3933 4.20561C11.3933 3.02804 11.618 2.04673 12.0674 1.26168C12.5618 0.42056 13.4382 0 14.6966 0C15.9101 0 16.7416 0.42056 17.191 1.26168C17.6854 2.04673 17.9326 3.02804 17.9326 4.20561H18C18 4.26168 17.9775 4.28972 17.9326 4.28972C17.9326 4.68224 17.8876 5.41122 17.7978 6.47663C17.7528 7.48598 17.5955 8.63551 17.3258 9.92523C17.0562 11.215 16.6067 12.5047 15.9775 13.7944C15.3933 15.028 14.5843 16.0374 13.5506 16.8224C12.5169 17.6075 11.2135 18 9.64045 18Z"
                    fill="#141414"
                  />
                </svg>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Testimonials
