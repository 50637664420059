import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

type Props = {}

const Services = (props: Props) => {
  const marketingImages = useStaticQuery(graphql`
    query {
      slide1: imageSharp(fluid: { originalName: { eq: "desktop1.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
      slide2: imageSharp(fluid: { originalName: { eq: "slide_2.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
      slide3: imageSharp(fluid: { originalName: { eq: "slide_3.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
      slide4: imageSharp(fluid: { originalName: { eq: "slide_4.png" } }) {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
    }
  `)

  const [currentImage, setCurrentImage] = useState('image1')
  const images = ['slide1', 'slide2', 'slide3', 'slide4']

  useEffect(() => {
    const interval = setInterval(() => {
      const currentIndex = images.indexOf(currentImage)
      const nextIndex = (currentIndex + 1) % images.length
      setCurrentImage(images[nextIndex])
    }, 5000)

    return () => clearInterval(interval)
  }, [currentImage, images])

  return (
    <section id="marketing-services" className="">
      <div className="">
        <div className=" bg-primary py-20 text-center">
          <h1 className="container secondary-font text-[#141414] font-normal text-[40px] md:text-[74px] md:leading-[112%]  tracking-[-0.01em]">
            services that set you up for success
          </h1>
        </div>
        <div className="py-20 container">
          <p className="text-white marketing-paragraph-style marketing-background-black ">
            We&apos;re a global creative & media agency. We leverage our
            expertise across a number of disciplines and we&apos;ll partner with
            you to ensure you have a seamless end to end digital experience, no
            matter where you are in your journey.
          </p>
          <p className="text-white marketing-paragraph-style marketing-background-black ">
            The future of the internet is now, don&apos;t get left behind.
          </p>
        </div>

        {/* <p className=" text-white marketing-paragraph-style marketing-background-black py-20 px-3">
            We&apos;re a global creative & media agency. We leverage our
            expertise across a number of disciplines and we&apos;ll partner with
            you to ensure you have a seamless end to end digital experience, no
            matter where you are in your journey. The future of the internet is
            now, don&apos;t get left behind.
          </p> */}

        <div className="max-w-[1920px] mx-auto px-3">
          {currentImage === 'slide1' ? (
            <GatsbyImage
              image={marketingImages.slide1.gatsbyImageData}
              alt="Slide 1"
              className=" "
            />
          ) : currentImage === 'slide2' ? (
            <GatsbyImage
              image={marketingImages.slide2.gatsbyImageData}
              alt="Slide 2"
              className=" "
            />
          ) : currentImage === 'slide3' ? (
            <GatsbyImage
              image={marketingImages.slide3.gatsbyImageData}
              alt="Slide 3"
              className=" "
            />
          ) : (
            <GatsbyImage
              image={marketingImages.slide4.gatsbyImageData}
              alt="Slide 4"
              className=" "
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default Services
