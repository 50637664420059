import React from 'react'

type Props = {}

const Newsletter = (props: Props) => {
  return (
    <section id="newsletter" className="bg-[#232646] py-6 ">
      <div className="flex flex-col gap-[10px] lg:grid lg:grid-flow-row lg:grid-cols-2 container">
        <div className="bg-[#F1F3E5] py-8 px-5 xl:px-12 xl:py-12 ">
          <h1 className="font-semibold text-[64px] tracking-[-0.03em] leading-[86%] xl:pr-44 xl:pb-6">
            Ready to take off?
          </h1>
          <p className="font-semibold text-xl xl:text-[34px] xl:leading-[40px] text-[#141414] mt-4 mb-12">
            Interested in hearing more about how we can help you prepare you for
            the next big transformation
          </p>
          <a
            className="secondary-font text-[#DDFF8D] py-3.5 px-16 text-xl font-normal  mx-auto relative inline-block"
            href="https://calendly.com/georges-d74/15min?month=2023-06"
            target="_blank">
            <span className="relative z-10">LETS TALK</span>
            <span className="bg-[#333549] absolute w-full h-full inset-0 -skew-x-[30deg]" />
          </a>
        </div>

        <div className="bg-primary py-8 px-5 xl:px-12 xl:py-12">
          <h1 className="font-semibold text-[64px] tracking-[-0.03em] leading-[86%]">
            Subscribe to our weekly newsletter{' '}
          </h1>
          <p className="font-semibold text-xl xl:text-[34px] xl:leading-[40px] text-[#141414] mt-4 mb-12">
            Not quite ready to talk to us yet? Subscribe to our newsletter to
            learn more about web3 and receive the latest customised content
          </p>
          <a
            className="secondary-font text-white  py-3.5 px-16 text-xl font-normal mx-auto relative inline-block"
            href="https://g5uqur4cj8d.typeform.com/to/sTgNWVuU"
            target="_blank">
            <span className="relative z-10">JOIN THE LIST</span>
            <span className="bg-[#141414] absolute w-full h-full inset-0 -skew-x-[30deg]" />
          </a>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
