import * as React from 'react'
import { IoClose } from 'react-icons/io5'

const PopUp = ({}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true)
    }, 45000)

    return () => clearTimeout(timer)
  }, [])

  if (!isOpen) return null

  return (
    <div className=" fixed w-full h-full inset-0 z-50 flex flex-col justify-center items-center">
      <div
        className="bg-[#DDFF8D] px-24 py-32 max-w-[680px] flex flex-col justify-between relative z-20"
        style={{
          clipPath: 'polygon(14% 7%, 88% 0, 100% 82%, 0% 100%)'
        }}>
        <button
          className="absolute top-10 right-32 mt-4 mr-4"
          onClick={() => setIsOpen(false)}>
          <IoClose className="text-[#333549] text-xl" />
        </button>

        <h2 className=" text-2xl lg:text-[40px] leading-tight text-center">
          Want to learn more about EIDGENSI & how we can collaborate together on
          your project?
        </h2>
        <a
          href="https://g5uqur4cj8d.typeform.com/to/sTgNWVuU?typeform-source=localhost"
          onClick={() => setIsOpen(false)}
          target="_blank"
          className="slanted-button secondary-font text-[#DDFF8D] py-3.5 px-16 text-xl font-normal  mx-auto mt-8 relative hover:-top-1">
          <span className="relative z-10">LETS TALK</span>
          <span className="bg-[#333549] absolute w-full h-full inset-0 -skew-x-[30deg]" />
        </a>
      </div>
      <span
        className="absolute bg-black/70 inset-0"
        onClick={() => setIsOpen(false)}
      />
    </div>
  )
}
export default PopUp
